import React, { useState, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Dialog } from "@headlessui/react"
import PropTypes from "prop-types"

export default function BoardMember({ image, name, bio, id }) {
  let [isOpen, setIsOpen] = useState(false)
  let closeButtonRef = useRef(null)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <div className="w-full flex justify-center" key={id}>
      <div className="w-full justify-center items-start flex-col prose prose-green selection:bg-green-200 hidden pb-2">
        <div className="w-1/2">
          <GatsbyImage
            image={image}
            className="phone:w-full phone:h-full aspect-h-9 aspect-w-16 rounded-md shadow-lg order-none laptop:order-2"
            alt={name}
          />
        </div>
        <div className="w-1/2">
          <div className="order-none laptop:order-1">
            <h3>{name}</h3>
          </div>
          <div
            className="laptop:order-3 prose prose-green"
            dangerouslySetInnerHTML={{ __html: bio }}
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <GatsbyImage
          image={image}
          className="w-full h-full desktop:h-48 rounded-t shadow-xl mt-2 pb-4"
          alt={name}
        />
        <div className="w-full mt-6 mb-3">
          <h3 className="text-center">{name}</h3>
        </div>
        <button
          className="w-full font-oswald text-lg tablet:text-xl bg-gradient-to-b from-green-500 to-green-700
          border border-green-900 shadow-md shadow-gray-900/80 text-white rounded p-2"
          onClick={openModal}
        >
          read bio
        </button>
      </div>
      <div className="relative hidden desktop:flex">
        <Dialog
          initialFocus={closeButtonRef}
          open={isOpen}
          onClose={closeModal}
          as="div"
          className="fixed z-20 inset-0 overflow-y-auto flex justify-center content-center items-center  bg-gray-900 bg-opacity-60 backdrop-filter backdrop-blur-md"
        >
          <Dialog.Overlay className="flex fixed inset-0 30 z-10" />
          <div className="w-full flex justify-center content-center items-center">
            <div className="phone:w-full phone:mx-4 phone:px-4 w-auto bg-white rounded flex justify-center flex-col p-6">
              <Dialog.Title>
                <div className="w-full flex flex-col justify-center items-center">
                  <div className="w-auto">
                    <h2>{name}</h2>
                  </div>
                </div>
              </Dialog.Title>
              <div
                className="mb-9 px-4 prose font-roboto"
                dangerouslySetInnerHTML={{ __html: bio }}
              />
              <button
                className="w-full font-oswald text-lg tablet:text-xl bg-gradient-to-b from-green-500 to-green-700
                border border-green-900 shadow-md shadow-gray-900/80 text-white rounded p-2"
                onClick={closeModal}
                ref={closeButtonRef}
              >
                close
              </button>
            </div>
          </div>
          <></>
        </Dialog>
      </div>
      <></>
    </div>
  )
}

BoardMember.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}
