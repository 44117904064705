import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BoardMember from "../components/boardMember"
import Advisor from "../components/advisor"

const RenderBody = ({ boardMembers, advisors, meta, socialCard }) => (
  <>
    <Helmet
      title={meta.title}
      titleTemplate={`Board of Directors & Advisors | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: `${meta.description}`,
        },
        {
          property: `og:url`,
          content: `${meta.url}/board-of-directors-and-advisors`,
        },
        {
          property: `og:title`,
          content: "Board of Directors & Advisors",
        },
        {
          property: `og:description`,
          content: `${meta.description}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: `@tivp_org`,
        },
        {
          name: `twitter:site`,
          content: `@tivp_org`,
        },
        {
          name: `twitter:title`,
          content: "Board of Directors & Advisors | TIVP",
        },
        {
          name: `twitter:description`,
          content: `${meta.description}`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
        {
          property: "og:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${meta.url}",
          "name": "${meta.title}",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "info@theinfinitevoiceproject.org",
            "contactType": "inquiries"
          }
        }
    `}
      </script>
    </Helmet>
    <div className="w-full h-full bg-white flex flex-col justify-center content-center items-center py-24 phone:px-8 px-0">
      <div className="w-full desktop:w-4/5 flex justify-center content-center flex-col">
        <div className="w-full mb-12">
          <h1>Board of Directors and Advisors</h1>
        </div>
        <div className="w-full">
          <h2>Board of Directors</h2>
        </div>
        <div className="w-full flex flex-col justify-center content-center">
          <div className="w-full grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-5 desktop:grid-cols-5 gap-12 laptop:divide-y laptop:divide-gray-300">
            {boardMembers.map((board, i) => (
              <BoardMember
                key={i}
                name={board.node.name}
                bio={board.node.bio.html}
                id={board.node.id}
                image={board.node.image.gatsbyImageData}
              />
            ))}
          </div>
          <div className="w-full mt-24">
            <div className="w-full">
              <h2>Board of Advisors</h2>
            </div>
            <div className="w-full grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-5 desktop:grid-cols-5 gap-12 laptop:divide-y laptop:divide-gray-300">
              {advisors.map((advisor, i) => (
                <Advisor
                  key={i}
                  name={advisor.node.name}
                  bio={advisor.node.bio.html}
                  id={advisor.node.id}
                  image={advisor.node.image.gatsbyImageData}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

const BoardDirectors = ({ data }) => {
  //Required check for no data being returned
  const boardMembers = data.allGraphCmsBoardMember.edges
  const advisors = data.allGraphCmsAdvisor.edges
  const meta = data.site.siteMetadata
  const socialCard = data.file

  if (!boardMembers && !advisors) return null

  return (
    <Layout>
      <RenderBody boardMembers={boardMembers} advisors={advisors} meta={meta} socialCard={socialCard} />
    </Layout>
  )
}

export default BoardDirectors

export const query = graphql`
  query BoardAdvisors {
    allGraphCmsBoardMember {
      edges {
        node {
          name
          id
          bio {
            html
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    allGraphCmsAdvisor {
      edges {
        node {
          name
          id
          bio {
            html
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    file(name: {eq: "SocialCardTemplate-Board"}) {
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
        image
        url
      }
    }
  }
`
